.modal
  width: 100vw
  height: 100vh
  display: flex
  justify-content: center
  align-items: center
  background: linear-gradient(226deg, #33D2FF 5.73%, #3D68DE 54.65%, #185AC2 96.75%)

.paper
  padding: 30px 24px
  border-radius: 16px
  max-width: 413px !important
  box-sizing: border-box
  position: relative
  overflow: hidden
  margin: auto
  display: flex
  flex-direction: column
  align-items: center
  text-align: center
